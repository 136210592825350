import React, { useRef, useEffect, useState } from "react";
import queryString from "query-string";
import {
  fetchArticleById,
  fetchAllArticles,
} from "../../../Redux/slices/articlesSlice";
import { useSelector, useDispatch } from "react-redux";
import { Footer, Header } from "../../common";
import { BlogHeader } from ".";
import moment from "moment/moment";
import { Link, useNavigate } from "react-router-dom";
import { FallingLines } from "react-loader-spinner";

const SingleArticle = ({}) => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  let urlParams = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const { article, articles } = useSelector((state) => state.article);
  const navigate = useNavigate();
  const [allArticles, setAllArticles] = useState([]);
  const loadArticle = useSelector((state) => state.article.loadArticle);

  useEffect(() => {
    dispatch(fetchArticleById(urlParams.post_id));
    dispatch(fetchAllArticles());

    if (articles.items instanceof Array) {
      let Articles = articles.items.map((art, index) => {
        return {
          ...art,
          post_id: art.sys.id,
          index: index,
          image: articles.includes.Asset.filter(
            (imageitem) => imageitem.sys.id === art.fields.image.sys.id
          ).map((image) => {
            return `https:${image.fields.file.url}`;
          })[0],
        };
      });

      setAllArticles(Articles);
    }
  }, [
    urlParams.post_id,
    Object.keys(article).length,
    Object.keys(articles).length,
  ]);

  const content = () => {
    return article.fields.content.content.map((cont) => (
      <>
        {cont.nodeType === "paragraph"
          ? cont.content.map((item) => (
              <div style={{ fontFamily: "Lato Regular" }} className="mb-3">
                {item.value}
              </div>
            ))
          : cont.content.map((item) => (
              <div
                className="mt-4 mb-2"
                style={{
                  fontFamily: "Carviar Dreams Bold",
                  fontSize: "17px",
                }}
              >
                {item.value}
              </div>
            ))}
      </>
    ));
  };

  const nextArticle = () => {
    let currentArticle = allArticles.filter(
      (item) => item.post_id === urlParams.post_id
    );

    if (currentArticle[0]["index"] + 1 === allArticles.length) {
      console.log("exausted", "allArticlesallArticles");
    } else {
      const nextArtcleIndex = currentArticle[0]["index"] + 1;
      const nextArticle = allArticles.filter(
        (item) => item.index === nextArtcleIndex
      );

      navigate(
        `/blog/post?post_id=${nextArticle[0]["post_id"]}&image=${nextArticle[0]["image"]}`
      );
      dispatch(fetchArticleById(nextArticle[0]["post_id"]));
    }
  };
  const prevArticle = () => {
    let currentArticle = allArticles.filter(
      (item) => item.post_id === urlParams.post_id
    );

    if (currentArticle[0]["index"] === 0) {
      console.log("exausted", "allArticlesallArticles");
    } else {
      const nextArtcleIndex = currentArticle[0]["index"] - 1;
      const nextArticle = allArticles.filter(
        (item) => item.index === nextArtcleIndex
      );

      navigate(
        `/blog/post?post_id=${nextArticle[0]["post_id"]}&image=${nextArticle[0]["image"]}`
      );
      dispatch(fetchArticleById(nextArticle[0]["post_id"]));
    }
  };

  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
        blogPropActive="active"
      />
      {loadArticle ? (
        <div className="d-flex justify-content-center my-5">
          <FallingLines
            visible={true}
            height="80"
            width="80"
            ariaLabel="falling-lines-loading"
            wrapperStyle={{ color: "blue" }}
            wrapperClass="dna-wrapper text-black"
            color="#0a62ae"
          />
        </div>
      ) : (
        <BlogHeader
          image={Object.keys(article).length > 0 ? urlParams.image : ""}
        />
      )}

      {loadArticle ? (
        <div className="d-flex justify-content-center my-5">
          <FallingLines
            visible={true}
            height="80"
            width="80"
            ariaLabel="falling-lines-loading"
            wrapperStyle={{ color: "blue" }}
            wrapperClass="dna-wrapper text-black"
            color="#0a62ae"
          />
        </div>
      ) : (
        <div className="my-3 container">
          <h6 style={{ fontFamily: "Carviar Dreams Bold" }}>
            Posted on:{" "}
            {Object.keys(article).length > 0
              ? article.fields.createdAt
                ? moment(article.fields.createdAt).format("LLL")
                : ""
              : ""}
          </h6>
          {Object.keys(article).length > 0 && (
            <>
              <h5
                style={{ fontFamily: "Carviar Dreams Bold" }}
                className="my-5 d-flex justify-content-center align-text-center"
              >
                {article.fields.title}
              </h5>
              <div
                className="shadow-sm p-5"
                style={{ fontFamily: "Lato Regular" }}
              >
                <div className="mb-5">{content()}</div>
                <div className="col-md-12 d-flex justify-content-between">
                  {urlParams.post_id !== "1" && (
                    <div className="">
                      <button
                        className="btn btn-primary article-btn"
                        onClick={() => {
                          prevArticle();
                        }}
                      >
                        <i className="fas fa-chevron-circle-left pe-2"></i>
                        Previous Article
                      </button>
                    </div>
                  )}
                  <div>
                    <Link
                      className="btn btn-primary article-btn btn-sm text-white mx-1"
                      to="/blog"
                    >
                      <i className="fas fa-reply-all pe-2"></i>
                      All Posts
                    </Link>
                  </div>

                  {articles.items instanceof Array ? (
                    articles.items.length >= 2 ? (
                      <div className="">
                        <button
                          className="btn btn-primary article-btn"
                          onClick={() => {
                            nextArticle();
                          }}
                        >
                          Next Article
                          <i className="fas fa-chevron-circle-right ps-2"></i>
                        </button>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Footer />
    </>
  );
};

export default SingleArticle;
