import React from "react";

const PowerOfWater = () => {
  return (
    <div className="power-of-water-section d-flex justify-content-center p-5">
      <div className="all-content col-md-12 d-flex align-content-center flex-row justify-content-center">
        <div className="m-3  p-5 rounded shadow d-flex bg-white align-content-center text-card flex-column justify-content-center col-md-7">
          <div
            className="text-title d-flex align-content-center flex-row justify-content-center"
            style={{
              fontFamily: "Carviar Dreams Bold",
            }}
          >
            The Power of Water
            <span className="tm"> &trade; </span>
          </div>
          <div
            className="text-content mt-2"
            style={{
              fontFamily: "Carviar Dreams Bold",
            }}
          >
            <div className="" style={{ fontFamily: "Lato Regular" }}>
              PowWater’s mission is to democratise access to clean water
            </div>
            <div className="pb-3" style={{ fontFamily: "Lato Regular" }}>
              PowWater's vision is to be the most trusted water supplier
              globally
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerOfWater;
