import React from "react";
import { googlePlay, appStore } from "../../../images";

const Download = () => {
  return (
    <>
      <div className="">
        <a href="https://apps.apple.com/ke/app/powwater/id1565603273">
          <img
            src={appStore}
            alt="App Store"
            className="image-fluid appstore-link-blog"
          />
        </a>
      </div>
      <div className="">
        <a href="https://play.google.com/store/apps/details?id=com.powwater.customer">
          <img
            src={googlePlay}
            alt="App Store"
            className="image-fluid playstore-link-blog"
          />
        </a>
      </div>
    </>
  );
};

export default Download;
