import moment from "moment";
import React, { useEffect, Fragment } from "react";
import { FollowUs, Download } from ".";
import { fetchAllArticles } from "../../../Redux/slices/articlesSlice";
import { useSelector, useDispatch } from "react-redux";
import { FallingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RecentArticles = () => {
  const dispatch = useDispatch();
  const allArticles = useSelector((state) => state.article.articles);
  const loadArticles = useSelector((state) => state.article.loadArticles);

  useEffect(() => {
    dispatch(fetchAllArticles());
  }, []);

  let powUrl = (post_id, image) => {
    return encodeURIComponent(
      `${process.env.REACT_APP_ENVIRONMENT}/blog/post?post_id=${post_id}&image=${image}`
    );
  };

  const displayArticles =
    allArticles.items instanceof Array
      ? allArticles.items.slice(0, 3).map((item, index) => {
          return (
            <div
              key={index}
              className="d-flex justify-content-between my-2 shadow-sm flex-wrap image-card-mobile"
            >
              <Link
                className=" col-md-3 image-card-mobile d-flex align-items-center px-2"
                to={{
                  pathname: `/blog/post`,
                  search: `?post_id=${
                    item.sys.id
                  }&image=${allArticles.includes.Asset.filter(
                    (imageitem) => imageitem.sys.id === item.fields.image.sys.id
                  ).map((image) => `https:${image.fields.file.url}`)}`,
                }}
              >
                {allArticles.includes.Asset.filter(
                  (imageitem) => imageitem.sys.id === item.fields.image.sys.id
                ).map((image, index) => (
                  <img
                    src={`https:${image.fields.file.url}`}
                    alt="post"
                    className="image-fluid mt-5"
                    height="100"
                    width="200"
                    key={index}
                  ></img>
                ))}
              </Link>
              <div className="col-md-8 p-4">
                <div className="mb-3">
                  <h6 className="" style={{ fontSize: "13px" }}>
                    {moment(item.fields.createdAt).format("DD/MM/YYYY")}
                  </h6>
                </div>
                <Link
                  className="d-flex flex-row"
                  style={{ fontFamily: "Carviar Dreams Bold" }}
                  to={{
                    pathname: `/blog/post`,
                    search: `?post_id=${
                      item.sys.id
                    }&image=${allArticles.includes.Asset.filter(
                      (imageitem) =>
                        imageitem.sys.id === item.fields.image.sys.id
                    ).map((image) => `https:${image.fields.file.url}`)}`,
                  }}
                >
                  <h6 className="pb-3">{item.fields.title}</h6>
                </Link>

                <Link
                  to={{
                    pathname: `/blog/post`,
                    search: `?post_id=${
                      item.sys.id
                    }&image=${allArticles.includes.Asset.filter(
                      (imageitem) =>
                        imageitem.sys.id === item.fields.image.sys.id
                    ).map((image) => `https:${image.fields.file.url}`)}`,
                  }}
                >
                  <div>
                    {item.fields.content.content
                      .slice(0, 2)
                      .map((cont, index) => (
                        <Fragment key={index}>
                          {cont.nodeType === "paragraph"
                            ? cont.content.map((item, index) => (
                                <div
                                  style={{ fontFamily: "Lato Regular" }}
                                  key={index}
                                >
                                  {item.value.slice(0, 256)}...
                                </div>
                              ))
                            : cont.content.map((item, index) => (
                                <div
                                  className="my-2"
                                  style={{
                                    fontFamily: "Carviar Dreams Bold",
                                    fontSize: "15px",
                                  }}
                                  key={index}
                                >
                                  {item.value}
                                </div>
                              ))}
                          {}
                        </Fragment>
                      ))}
                  </div>
                </Link>
                <div>
                  <div className="d-flex col-md-6 justify-content-between pt-4">
                    <span style={{ fontFamily: "Carviar Dreams Bold" }}>
                      Share on
                    </span>
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${powUrl(
                        item.sys.id,
                        allArticles.includes.Asset.filter(
                          (imageitem) =>
                            imageitem.sys.id === item.fields.image.sys.id
                        ).map((image) => `https:${image.fields.file.url}`)
                      )}&quote=${item.fields.title}!`}
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook text-primary fa-lg"></i>
                    </a>
                    <a
                      href={`https://twitter.com/intent/tweet?text=${item.fields.title}!&url=${powUrl(
                        item.sys.id,
                        allArticles.includes.Asset.filter(
                          (imageitem) =>
                            imageitem.sys.id === item.fields.image.sys.id
                        ).map((image) => `https:${image.fields.file.url}`)
                      )}`}
                      target="_blank"
                    >
                      <i className="fa-brands fa-twitter text-primary fa-lg"></i>
                    </a>
                    <a
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${powUrl(
                        item.sys.id,
                        allArticles.includes.Asset.filter(
                          (imageitem) =>
                            imageitem.sys.id === item.fields.image.sys.id
                        ).map((image) => `https:${image.fields.file.url}`)
                      )}`}
                      target="_blank"
                    >
                      <i className="fa-brands fa-linkedin text-primary fa-lg"></i>
                    </a>
                    <a
                      href={`https://t.me/share/url?url=${powUrl(
                        item.sys.id,
                        allArticles.includes.Asset.filter(
                          (imageitem) =>
                            imageitem.sys.id === item.fields.image.sys.id
                        ).map((image) => `https:${image.fields.file.url}`)
                      )}&text=${item.fields.title}`}
                      target="_blank"
                    >
                      <i className="fa-brands fa-telegram text-primary fa-lg"></i>
                    </a>
                    <a
                      href={`https://wa.me/?text=${item.fields.title}%20${powUrl(
                        item.sys.id,
                        allArticles.includes.Asset.filter(
                          (imageitem) =>
                            imageitem.sys.id === item.fields.image.sys.id
                        ).map((image) => `https:${image.fields.file.url}`)
                      )}`}
                      target="_blank"
                      data-action="share/whatsapp/share"
                    >
                      <i className="fa-brands fa-whatsapp text-primary fa-lg"></i>
                    </a>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `${
                            process.env.REACT_APP_ENVIRONMENT
                          }/blog/post?post_id=${
                            item.sys.id
                          }&image=${allArticles.includes.Asset.filter(
                            (imageitem) =>
                              imageitem.sys.id === item.fields.image.sys.id
                          ).map((image) => `https:${image.fields.file.url}`)}`
                        );
                        toast("Link Copied!");
                      }}
                    >
                      <i className="far fa-copy text-primary fa-lg"></i>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      : null;

  return (
    <div className="container mb-5">
      <h5
        style={{ fontFamily: "Carviar Dreams Bold" }}
        className="my-5 header-posts-mobile"
      >
        Recent Posts
      </h5>
      <div className="d-flex justify-content-between col-md-12 flex-wrap">
        <div className="col-md-8">
          {loadArticles ? (
            <div className="d-flex justify-content-center my-5">
              <FallingLines
                visible={true}
                height="80"
                width="80"
                ariaLabel="falling-lines-loading"
                wrapperStyle={{ color: "blue" }}
                wrapperClass="dna-wrapper text-black"
                color="#0a62ae"
              />
            </div>
          ) : (
            displayArticles
          )}
        </div>
        <div className="col-md-3">
          <div className="shadow-sm p-2 d-flex justify-content-center flex-column align-items-center">
            <div className="border-bottom d-flex justify-content-center flex-column align-items-center col-md-12">
              <h5
                style={{ fontFamily: "Carviar Dreams Bold" }}
                className="d-flex align-text-center my-3"
              >
                Follow Us
              </h5>
              <FollowUs />
            </div>
            <div className="d-flex justify-content-center flex-column align-items-center col-md-12">
              <h5
                style={{ fontFamily: "Carviar Dreams Bold" }}
                className="d-flex align-text-center my-3 text-wrap"
              >
                Download The App to Order
              </h5>
              <Download />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentArticles;
