import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axios } from "../../utils";

const initialState = {
  articles: [],
  categories: [],
  article: {},
  loadArticles: false,
  loadArticle: false,
  loadCategories: false,
};

// fetch all articles
export const fetchAllArticles = createAsyncThunk(
  "articles/fetch",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`/environments/master/entries?access_token=dQDEMiuldfRFtBhenoZlq9-thMXFBPiQA97guSkwACg`, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Authorization: `Bearer dQDEMiuldfRFtBhenoZlq9-thMXFBPiQA97guSkwACg`,
        },
      });

      // console.log(data, "datadata");
      if (data) {
        return data;
      }
      return rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
// fetch all categories
export const fetchAllCategories = createAsyncThunk(
  "categories/fetch",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(`categories`);

      console.log(data, "allCategories");
      if (data) {
        return data;
      }
      return rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// fetch single article
export const fetchArticleById = createAsyncThunk(
  "articles/fetcharticleById",
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await axios.get(
        `/environments/master/entries/${payload}?content_type=powwaterBlogPosts`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
            Authorization: `Bearer dQDEMiuldfRFtBhenoZlq9-thMXFBPiQA97guSkwACg`,
          },
        }
      );
      if (data) {
        return data;
      }
      return rejectWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllArticles.pending]: (state, action) => {
      state.loadArticles = true;
    },
    [fetchAllArticles.fulfilled]: (state, action) => {
      state.loadArticles = false;
      state.articles = action.payload;
    },
    [fetchAllArticles.rejected]: (state, action) => {
      state.loadArticles = false;
    },
    [fetchArticleById.pending]: (state, action) => {
      state.loadArticle = true;
    },
    [fetchArticleById.fulfilled]: (state, action) => {
      state.loadArticle = false;
      state.article = action.payload;
    },
    [fetchArticleById.rejected]: (state, action) => {
      state.loadArticle = false;
    },
    [fetchAllCategories.pending]: (state, action) => {
      state.loadCategories = true;
    },
    [fetchAllCategories.fulfilled]: (state, action) => {
      state.loadCategories = false;
      state.categories = action.payload;
    },
    [fetchAllCategories.rejected]: (state, action) => {
      state.loadCategories = false;
    },
  },
});

// export const {} = articlesSlice.actions;

export default articlesSlice.reducer;
