import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Header, Footer, TermsCard, PolicyCard, PolicyTable } from "../common";

const WaterQuality = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container mb-5">
        <div
          className="text-black fw-bold fs-4 my-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          PowWater – Understanding Water Quality
        </div>

        <div className="shadow p-4">
          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Understanding Water Quality: Essential Terms Explained
              </span>
            }
            titleContent="At PowWater, we are passionate about delivering clean and safe water. We understand the importance of water quality and its impact on the health and well-being of our customers. In this Q&A article, we aim to provide you with a better understanding of water quality and key terms such as E. coli, Total Dissolved Solids (TDS), pH, and Turbidity. 

            
            "
          />
          <TermsCard content="By familiarizing yourself with these terms, you will be better equipped to interpret the water quality certificates you receive when placing an order through our innovative PowWater app." />

          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Why does PowWater care about water quality?
              </span>
            }
            titleContent="PowWater is committed to ensuring that our customers receive water of the highest quality. We believe that clean water is essential for the well-being and productivity of businesses. By prioritizing water quality, we strive to provide a safe and reliable water supply that meets stringent standards. Our commitment to water quality is rooted in our mission to promote sustainability in Nairobi."
          />
          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                What is E. coli?
              </span>
            }
            titleContent="E. coli (Escherichia coli) is a type of bacteria commonly found in the intestines of humans and animals. While most strains of E. coli are harmless, some can cause severe illness. The presence of E. coli in water indicates contamination with fecal matter, which may contain harmful pathogens. Regular testing for E. coli helps ensure that the water we deliver is free from harmful bacteria.
            ."
          />
          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                What are Total Dissolved Solids (TDS)?
              </span>
            }
            titleContent="TDS refers to the total concentration of inorganic salts, minerals, metals, and other dissolved substances present in water. These substances can include calcium, magnesium, potassium, sodium, and various ions. TDS is measured in parts per million (ppm) or milligrams per liter (mg/L). Monitoring TDS levels is crucial, as high concentrations can affect the taste, appearance, and safety of the water."
          />
          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                What is pH?
              </span>
            }
            titleContent="pH is a measure of the acidity or alkalinity of water. It is determined by the concentration of hydrogen ions in the water. The pH scale ranges from 0 to 14, with 7 considered neutral. Values below 7 indicate acidity, while values above 7 indicate alkalinity. Maintaining an appropriate pH level is important because extreme pH values can affect the water's taste, corrosion potential, and its ability to support aquatic life."
          />

          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                What is Turbidity?
              </span>
            }
            titleContent="Turbidity refers to the cloudiness or haziness of water caused by the presence of suspended particles. These particles can include silt, clay, organic matter, and microscopic organisms. Turbidity is measured in nephelometric turbidity units (NTU) and is an important indicator of water quality. High turbidity levels can impact water clarity, hinder disinfection processes, and serve as a potential habitat for bacteria and other harmful microorganisms."
          />
  
          <PolicyCard
            title={
              <span
                className="fs-3 fw-bold"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Conclusion
              </span>
            }
            titleContent="Understanding water quality is crucial for the well-being of businesses and individuals alike. By familiarizing yourself with terms such as E. coli, TDS, pH, and turbidity, you can interpret the water quality certificates provided by PowWater accurately. We are committed to delivering clean and safe water to our customers and by prioritizing water quality, we aim to contribute to a healthier and more sustainable future."
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WaterQuality;
