import classNames from "classnames";
import React from "react";

const TermsCard = ({ number, title, content, moreClass }) => {
  return (
    <div
      className="my-4 about-text fs-6"
      style={{ fontFamily: "Lato Regular" }}
    >
      <span style={{ fontFamily: "Open Sans Regular" }}>{number}</span>
      <span className={classNames("text-decoration-underline", moreClass)}>
        {title}
      </span>
      {content}
    </div>
  );
};

export default TermsCard;
