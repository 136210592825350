import React, { useRef } from "react";
import { Header, Footer } from "../../common";

const FullStack = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container mb-5">
        <div
          className="position-title p-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Full-Stack Engineer
        </div>
        <div className="shadow p-4">
          <div
            className="position-sub-title mt-3"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About PowWater
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Urban water infrastructure systems are failing the inhabitants of
            rapidly growing low and middle-income cities around the world with
            unreliable water supply and unsafe water quality. Informal private
            water vendors fill this gap but the service comes at a high social
            cost: exploitative prices, poor water quality, and use of
            unsustainable water sources. PowWater's solution is meant to disrupt
            these market dynamics to make the water purchasing experience more
            reliable, affordable, and transparent.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About the role
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            PowWater is looking for a full time full stack engineer who will be
            responsible for both frontend and backend development of our suite
            of products which include but are not limited to mobile and web
            applications, APIs and servers and databases.
          </div>
          <div
            className="about-text my-3"
            style={{ fontFamily: "Lato Regular" }}
          >
            <span className="text-black fw-bold">Location:</span> In-person
            attendance for all Kenyan candidates at our office. Remote
            attendance for all non-Kenyan candidates
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Responsibilities
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Developing mobile and web architecture</li>
              <li>
                Implementing UI and UX features on mobile or web based on user
                requirements
              </li>
              <li>
                Ensuring cross-platform optimization and responsiveness of
                PowWater’sproduct suite
              </li>
              <li>
                Overseeing overall rollout of features,products and verticals
              </li>
              <li>Understanding product user needs</li>
              <li>
                Develop specifications for new features/products and maintaining
                existing products
              </li>
              <li>Integrate the backend with UI elements</li>
              <li>
                Product performance improvement,balancing,usability,automation
              </li>
              <li>
                Server side optimization and maintenance to improve product
                performance and reduce downtime
              </li>
              <li>
                Work with frontend engineers to ensure proper implementation of
                endpoints
              </li>
              <li>
                Work with the data engineers to ensure database
                stability,reliability and availability
              </li>
              <li>
                Integrate into external APIs to improve the functionality of our
                products
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Qualifications
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>At least 3-4 years experience as a full-stack developer</li>
              <li>
                Proficiency in Javascript libraries and frameworks,and
                environments-ReactJS, Angular JS and Node JS.
              </li>
              <li>Experience with React Native preferred but not required.</li>
              <li>Mobile development experience is a plus</li>
              <li>Knowledge working with REST APIs</li>
              <li>Experience with responsive design</li>
              <li>
                Experience with cloud computing technologies such as Google
                Cloud Platform
              </li>
              <li>
                Previous experience working in a startup environment preferred
                but not required
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            How to Apply
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Send the following to{" "}
            <span className="text-primary">contact@powwater.com</span>:
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Resume</li>
              <li>Link to LinkedIn profile</li>
              <li>Link to Github profile</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FullStack;
