import React, { useRef } from "react";
import { Header, Footer, TermsCard, PolicyCard } from "../common";

const TermsAndConditionVendor = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container  mb-5">
        <div
          className="text-black fw-bold fs-4 my-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Additional Terms and Conditions for PowWater Vendors
        </div>
        <div className="shadow p-4">
          <TermsCard content="The following terms and conditions (“Additional Terms”) apply to those persons and entities using the Services to offer and sell water to PowWater users (respectively, “Vendors” and “Users”) and apply in addition to the PowWater Terms of Service. In the event of any conflict between these Additional Terms and the Terms of Service, the Additional Terms will apply." />
          <TermsCard
            number="1. "
            title="The Role of Vendors: "
            content="  PowWater’s Services connect Vendors with Users and Drivers to facilitate the ordering and delivery of water. Through the Services, Vendors can sell water to Users, for delivery by Drivers. Vendors are responsible for fulfilling all confirmed orders, at the quantities, qualities, and timeframes specified by the Service or Users. Vendors are also responsible for responding to and managing any User complaints or concerns regarding the water."
          />
          <TermsCard
            number="2. "
            title="Water Testing: "
            content=" You agree that PowWater may, at its own expense, randomly test your water supply quality using a third-party lab partner. You agree that PowWater may post the results of such testing in the Services for User review and that you shall receive testing results through PowWater. You agree to not directly seek out sample test results via the testing lab."
          />
          <TermsCard
            number="3. "
            title="Representations and Warranties: "
            content=" In addition to the representations and warranties in the Terms of Service, you represent and warrant that you offer water of potable quality, fit for human consumption. You represent and warrant that you are in compliance with all applicable laws and regulations regarding water production and distribution to individuals or businesses."
          />
          <TermsCard
            number="4. "
            title="Personal Conduct: "
            content=" You acknowledge and understand that you are providing critical water supplies to Users. You agree that you will maintain accurate listings on the Services for any water for sale and will disclose any and all material information for Users about water, including the quantity and quality thereof."
          />
          <TermsCard content="In fulfilling orders, you will have access to potentially sensitive information, such as individual’s names and addresses. You agree that you will not share or use sensitive information for any purpose other than making a delivery. You will also have the ability to contact Drivers directly. You agree that you will not contact Drivers other than for the purposes of arranging deliveries." />
          <TermsCard content="You agree that you will promptly inform PowWater, Drivers, and Users (as applicable) of any delays or issues in fulfilling orders, and if you are unable to complete a confirmed order." />
          <TermsCard content="You acknowledge and agree that any orders you confirm through the Services will be completed by you; you will not ask any other individual or entity to fulfill an order on your behalf. You also will not separately contract with Users or Drivers outside of the Services to receive or complete orders or charge Users a higher price than the one you agreed to via the Service." />

          <TermsCard
            number="5. "
            title="PowWater is Not Responsible: "
            content=" PowWater only enables connections between Users, Drivers, and Vendors. PowWater is not responsible for the performance or actions of Users, Drivers, or you, nor does it have control over the quality, timing, legality, failure to provide, or any other aspect whatsoever of Users, Drivers, or you. PowWater makes no representations about the reliability or accuracy of information provided by Users or Drivers, including regarding order details, delivery pick-up time, or other aspects of orders or delivery. PowWater explicitly disclaims all responsibility and liability for damage to your facilities or person that you may incur as a Vendor. PowWater does not assume any responsibility, nor will PowWater be liable, for any health and safety problems experienced by Users for the consumption of water purchased through the Services."
          />
          <TermsCard
            number="6. "
            title="Independent Contractors: "
            content=" Vendors are independent contractors and not employees of PowWater. PowWater does not certify Vendors’ qualifications. PowWater does not supervise, direct, control or monitor Vendors’ work and is not responsible for the work performed or the delivery process in any manner."
          />
          <TermsCard
            number="7. "
            title="Payment: "
            content=" PowWater shall receive all payments (purchase price) made for each completed water order made. PowWater shall thereafter deduct a commission for every completed order at the rate of [ ] and remit the remaining amount to you as payment for each completed water order. The commission shall be deducted as soon as the water order is made, and purchase price is paid (regardless of whether the amount is fully or partly paid). The purchase price less the commission shall be remitted as payment following delivery of each order via your mobile money account (digital wallet, Mpesa, etc.) on file. You shall be solely responsible for paying any applicable taxes on money earned as a Vendor. Notwithstanding any provisions herein, no payments will be paid to you should the terms of Service or any agreement between you and PowWater be terminated."
          />
          <TermsCard
            number="8. "
            title="Suspension and Termination: "
            content=" PowWater reserves the right to suspend or terminate a Vendor at any time, for any reason. Upon termination, PowWater will remit to you any earned payments owed via your payment method on file."
          />
          <TermsCard
            number="9. "
            title="No Agency: "
            content=" Nothing herein contained shall be deemed to create an agency, joint venture, amalgamation, partnership, employment or similar relationship between PowWater and the Vendors. Notwithstanding any of the provisions of these Terms and Conditions, neither Party shall at any time enter into, incur, or hold itself out to third Parties as having authority to enter into or incur, on behalf of the other Party, any commitment, expense, or liability whatsoever, and all contracts, expenses and liabilities undertaken or incurred by one Party in connection with or relating to the offering and selling of water shall be undertaken, incurred or paid exclusively by that Party, and not as an agent or representative of the other Party.
 "
          />
          <TermsCard
            number="10. "
            title="Non-Solicitation: "
            content=" For the duration that the Vendors will be providing their services under PowWater’s Terms of Service and these Additional Terms, the Vendors irrevocably and unconditionally agree that, whether directly or indirectly and either solely or jointly, they will not:"
          />
          <PolicyCard
            list={[
              "influence or canvass or try to persuade the Drivers to provide them with the personal information and/or any other information relating to the PowWater Users;",
              "influence or canvass or try to persuade any PowWater User to cease using the PowWater Services or use the services of a company that offers services that are similar to the PowWater Services;",
              "influence or canvass or try to persuade any PowWater User to engage Vendors directly; and/or",
              "encourage, entice, persuade, induce or in any way offer employment to the Drivers or cause the Drivers to be employed by a company that offers services that are similar to the PowWater Services.",
            ]}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionVendor;
