import React, { useRef } from "react";
import { Header, Footer } from "../common";
import { Banner } from ".";
import { AboutUs } from "./About";
import { How } from "./How";

const Home = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <Banner />
      <AboutUs myRef={myRefWhy} />
      <How myRef={myRefHow} />
      <Footer />
    </>
  );
};

export default Home;
