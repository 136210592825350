import React, { useRef, useEffect } from "react";
import { Header, Footer } from "../../common";
import { ArticlesSlide, RecentArticles, ScrollableArticles } from ".";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllArticles } from "../../../Redux/slices/articlesSlice";

const AllArticles = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  const dispatch = useDispatch();
  const allArticles = useSelector((state) => state.article);

  useEffect(() => {
    dispatch(fetchAllArticles());
  }, []);

  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
        blogPropActive="active"
      />
      <ArticlesSlide articles={allArticles.articles} />
      <RecentArticles />
      <ScrollableArticles allArticles={allArticles.articles} />
      <Footer />
    </>
  );
};

export default AllArticles;
