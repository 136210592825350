import React from "react";
import { transperency, trust, challenge, compassion } from "../../../images";

const Values = () => {
  return (
    <div className="shadow-sm">
      <div className="container ">
        <div
          className="d-flex justify-content-center career-title align-items-center py-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Our Values
        </div>
        <div className="py-3 d-flex justify-content-between flex-wrap">
          <div className="d-flex flex-column align-items-center">
            <div className="rounded-circle bg-secondary lottie-value d-flex align-items-center justify-content-center">
              <img src={challenge} alt="Challenge" className="image-fluid value-icon" />
            </div>
            <div
              className="my-4 fw-bold fs-4 value-text"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              Challenge
            </div>
          </div>
          <div className="d-flex flex-column align-items-center ">
            <div className="rounded-circle bg-secondary lottie-value d-flex align-items-center justify-content-center">
              <img src={transperency} alt="Challenge" className="image-fluid value-icon" />
            </div>
            <div
              className="my-4 fw-bold fs-4 value-text"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              Transparency
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="rounded-circle bg-secondary lottie-value d-flex align-items-center justify-content-center">
              <img src={compassion} alt="Challenge" className="image-fluid value-icon" />
            </div>
            <div
              className="my-4 fw-bold fs-4 value-text"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              Compassion
            </div>
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="rounded-circle bg-secondary lottie-value d-flex align-items-center justify-content-center">
              <img src={trust} alt="Challenge" className="image-fluid value-icon" />
            </div>
            <div
              className="my-4 fw-bold fs-4 value-text"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              Trust
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
