import React from "react";
import { careerHero } from "../../../images";

const CareerBanner = () => {
  return (
    <div className="" id="banner">
      <div className="container py-5">
        <div className="d-flex justify-content-between flex-wrap d-flex align-items-center banner-content">
          <div className="col-md-5">
            <div
              className="banner-text"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              Create an impact through your work
            </div>
            <div className="how-text" style={{ fontFamily: "Lato Regular" }}>
              PowWater is built on a culture of transparency. We are a
              passionate team of individuals driven by the larger mission to
              democratize access to clean water
            </div>
          </div>
          <div className="d-flex justify-content-start col-md-5">
            <img
              src={careerHero}
              alt="PowWater"
              height="400"
              className="image-fluid career-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBanner;
