import React, { useState, useEffect } from "react";
import { logo } from "../../images";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from ".";
import ReactGA from "react-ga";
import { trackEvent } from "../../googleAnalytics";

const Header = ({
  scrollToWhy,
  scrollToHow,
  scrollToHome,
  myRef,
  careerActive,
  blogPropActive,
}) => {
  const location = useLocation();

  console.log("width:" + window.innerWidth);
  console.log("height:" + window.innerHeight);

  const [homeActive, setHomeActive] = useState(
    !location.state ? "active" : location.state.activeState.home
  );
  const [whyActive, setWhyActive] = useState(
    !location.state ? "" : location.state.activeState.why
  );
  const [howActive, setHowActive] = useState(
    !location.state ? "" : location.state.activeState.how
  );
  const [jonActive, setJoinActive] = useState(
    !location.state ? "" : location.state.activeState.join
  );
  const [blogActive, setBlog] = useState(
    !location.state ? "" : location.state.activeState.blog
  );

  useEffect(() => {
    if (careerActive === "active") {
      setJoinActive(careerActive);
      setHomeActive("");
    }
    if (blogPropActive === "active") {
      setBlog(blogPropActive);
      setHomeActive("");
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleButtonClick = () => {
    trackEvent("event", "button_click", {
      event_category: "download_app_to_order",
      event_label: "download_app_to_order",
    });
  };
  return (
    <div className="container-fluid bg-white p-0 header" ref={myRef}>
      <nav className="container navbar navbar-expand-lg navbar-light">
        <Link to="/">
          <img
            src={logo}
            alt="PowWater"
            className="image-fluid"
            id="site-logo"
          />
        </Link>
        <button
          className="navbar-toggler ms-auto"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex pl-4 align-items-center">
            <NavLink
              active={homeActive}
              to="/"
              title="Home"
              activeState={{
                home: "active",
                why: "",
                how: "",
                join: "",
                blog: "",
              }}
              onClick={() => {
                setHomeActive("active");
                setWhyActive("");
                setHowActive("");
                setJoinActive("");
                jonActive !== "active" && scrollToHome();
              }}
            />
            <NavLink
              active={whyActive}
              to="/why"
              title="About Us"
              activeState={{
                home: "",
                why: "active",
                how: "",
                join: "",
                blog: "",
              }}
              onClick={() => {
                setHomeActive("");
                setWhyActive("active");
                setHowActive("");
                setJoinActive("");
                jonActive !== "active" && scrollToWhy();
              }}
            />
            <NavLink
              active={howActive}
              to="/how"
              title="How"
              activeState={{
                home: "",
                why: "",
                how: "active",
                join: "",
                blog: "",
              }}
              onClick={() => {
                setHomeActive("");
                setWhyActive("");
                setHowActive("active");
                setJoinActive("");
                jonActive !== "active" && scrollToHow();
              }}
            />
            <NavLink
              active={jonActive}
              to="/join-us"
              title="Careers"
              activeState={{
                home: "",
                why: "",
                how: "",
                join: "active",
                blog: "",
              }}
              onClick={() => {
                setHomeActive("");
                setWhyActive("");
                setHowActive("");
                setJoinActive("active");
              }}
            />
            <NavLink
              active={blogActive}
              to="/blog"
              title="Blog"
              activeState={{
                home: "",
                why: "",
                how: "",
                join: "",
                blog: "active",
              }}
              onClick={() => {
                setHomeActive("");
                setWhyActive("");
                setHowActive("");
                setJoinActive("");
                setBlog("active");
              }}
            />
            <li
              className="nav-item mobile-margin"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              <Link
                className="btn text-white"
                target="_blank"
                id="download-btn"
                to="/home-download"
                onClick={handleButtonClick}
              >
                Download App To Order
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Header;
