import React from "react";
import Lottie from "lottie-react";
import classNames from "classnames";

const AnimationCard = ({ animationData, title, content, more_classes }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <div className="rounded-circle bg-secondary lottie d-flex align-items-center justify-content-center">
        <Lottie
          animationData={animationData}
          loop={true}
          className={classNames("lottie", more_classes)}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center flex-column ">
        <div
          className="my-4 fw-bold fs-5 how-sub-title"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          {title}
        </div>
        <div
          className="fs-6 pb-4 how-text"
          style={{ fontFamily: "Lato Regular" }}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default AnimationCard;
