import React, { useState } from "react";
import pow_logo from "../../images/pow_logo.png";
import Swal from "sweetalert2";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import axios from "axios";

const TempPage = (props) => {
  const [pin, setPin] = useState("");
  const location = useLocation();

  const { ref } = queryString.parse(location.search);

  const updatePin = () => {
    if (!pin) {
      Swal.fire("Error", "Pin is required", "error");
    } else {
      axios
        .post(`https://production-backend-6bpq7f4ctq-de.a.run.app/kra/${ref}`, {
          kra_pin: pin,
        })
        .then((response) => {
          Swal.fire("Success", response.data.message, "success");
          setPin("");
        })
        .catch((err) => {
          Swal.fire("Error", err.response.data.message, "error");
        });
    }
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="d-flex flex-column">
        {ref ? (
          <>
            <img src={pow_logo} className="image-fluid" height="150" />
            <div className="mb-3 d-flex justify-content-center align-items-center flex-column mt-4">
              <input
                type="text"
                className="form-control"
                id="formGroupExampleInput"
                placeholder="Enter your KRA PIN number"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
            <button
              className="btn btn-primary mt-3"
              onClick={() => updatePin()}
            >
              Submit
            </button>
          </>
        ) : (
          <div className="p-3">
            <img src={pow_logo} className="image-fluid" height="150" />
            <div className="mb-3 d-flex justify-content-center align-items-center flex-column mt-4">
              <div className="">
                There was a problem loading the page, kindly send an email to
                maria@powwater.com
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TempPage;
