import React, { useState } from "react";

import classNames from "classnames";
import {
  boostvc,
  logos,
  heritage,
  cleanshelf,
  tender_care,
  vibanda,
} from "../../../images";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomerFeedback = () => {
  const [feedbacks] = useState([
    {
      name: "Brian, Vibanda Village",
      backgroundColor: "bg-white",
      image: vibanda,
      company: "",
      height: "60px",
      width: "130px",
      margingBottom: "10px",
      comment:
        "We are happy to be working with PowWater. They have been on time, efficient, friendly and over all just a pleasure to work with. I believe that this is the level of customer service that we need from a company and they have completely exceeded my expectations. If you are looking for a new clean water delivery company I highly recommend them!",
    },
    {
      name: "Liz, Heritage Properties",
      backgroundColor: "bg-white",
      image: heritage,
      height: "100px",
      width: "100px",
      margingBottom: "10px",
      comment:
        "The PowWater App is awesome and very reliable. It gives real time updates of your order, not to mention the instant invoicing, making it transparent. Kudos PowWater for game changing the clean water delivery industry in Nairobi!",
    },
    {
      name: "Adam Draper, Partner, Boost VC",
      backgroundColor: "bg-white",
      image: boostvc,
      company: "",
      height: "30px",
      width: "100px",
      margingBottom: "10px",
      comment:
        "If PowWater succeeds at their mission, they will be the most important company in Africa.",
    },
    {
      name: "Samwel, Cleanshelf",
      backgroundColor: "bg-white",
      image: cleanshelf,
      company: "",
      height: "60px",
      width: "150px",
      margingBottom: "10px",
      comment:
        "I can confirm that the experience has been really good. The PowWater app has been really convenient and helpful during follow ups. The clean water deliveries have been timely and we appreciate it. In general our experience has been very good.",
    },
    {
      name: "Fatima, Tender Care Dental Clinic",
      backgroundColor: "bg-white",
      image: tender_care,
      company: "",
      height: "100px",
      width: "100px",
      margingBottom: "10px",
      comment:
        "We are happy clients. The PowWater app has made clean water accessibility fast and easy, as everything is done and updated online. We recommend PowWater to anyone needing clean water in Nairobi!",
    },
    {
      name: "Ann, Logos Christian School",
      backgroundColor: "bg-white",
      image: logos,
      company: "",
      height: "100px",
      width: "100px",
      margingBottom: "10px",
      comment:
        "The PowWater App has been a game changer for us. You are always assured that the clean water will be delivered once you place the order. Having the business option on the App made us feel more elevated. We love that PowWater delivers high quality tested clean water and the team is always professional.",
    },
  ]);

  const displayFeedBack = feedbacks.map((item, index) => {
    return (
      <div
        className="px-5 py-3 d-flex flex-column align-items-center justify-content-center"
        key={index}
        style={{ fontFamily: "Lato Regular" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center how-text col-md-10 pt-5">
          <div className="text-center">
            <i className="fa-solid fa-quote-left text-primary pe-2"></i>
            {item.comment}{" "}
            <i className="fa-solid fa-quote-right text-primary ps-2"></i>
          </div>
          <div className="py-3" style={{ fontSize: "12px" }}>
            ~{item.name}
          </div>
          <img
            alt="client"
            src={item.image}
            style={{
              height: item.height,
              width: item.width,
              marginBottom: item.margingBottom,
            }}
          />
        </div>
      </div>
    );
  });

  return (
    <div className="shadow-sm">
      <div className="container pb-5">
        <div
          className="d-flex justify-content-center fw-bold  py-5 how-title"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          What people are saying about us:
        </div>
        <div className="d-flex justify-content-center col-md-12">
          <Carousel
            autoPlay
            showArrows={true}
            showStatus={false}
            className="col-md-8  feedback-width shadow rounded"
          >
            {displayFeedBack}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CustomerFeedback;
