import React, { useRef } from "react";
import { Header } from "../common";

const AccountDeletion = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <div>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="d-flex justify-content-center align-items-center">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSf7fAFIAE-cRLVqAiC5jHT7OCMTtZeqUuR-XCso9YHjwCXTNQ/viewform?embedded=true"
          width="640"
          height="689"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default AccountDeletion;
