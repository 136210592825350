import React from "react";

const FollowUs = () => {
  return (
    <div className="d-flex flex-row text-primary pt-2 pb-4">
      <div className="" style={{ cursor: "pointer" }}>
        <a
          href="https://www.facebook.com/Powwater1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-2x fa-facebook"></i>
        </a>
      </div>
      <div className="px-3" style={{ cursor: "pointer" }}>
        <a
          href="https://www.instagram.com/powwater"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-2x fa-instagram-square"></i>
        </a>
      </div>
      <div className="pe-3" style={{ cursor: "pointer" }}>
        <a
          href="https://www.tiktok.com/@heypow"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-2x fa-tiktok"></i>
        </a>
      </div>
      <div className="pe-3" style={{ cursor: "pointer" }}>
        <a
          href="https://twitter.com/HeyPowwater"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-2x fa-twitter"></i>
        </a>
      </div>
      <div className="" style={{ cursor: "pointer" }}>
        <a
          href="https://www.linkedin.com/company/powwater"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-2x fa-linkedin"></i>
        </a>
      </div>
    </div>
  );
};

export default FollowUs;
