import React, { Fragment } from "react";

const PolicyCard = ({ title, titleContent, list }) => {
  const displayList =
    list instanceof Array
      ? list.map((item, index) => (
          <Fragment key={index}>
            <li>{item}</li>
          </Fragment>
        ))
      : null;
  return (
    <div className="">
      <div className="" style={{ fontFamily: "Carviar Dreams Bold" }}>
        {title}
      </div>
      <div
        className="my-4 about-text fs-6"
        style={{ fontFamily: "Lato Regular" }}
      >
        {titleContent}
      </div>
      <div style={{ fontFamily: "Lato Regular" }}>
        <ul className="about-text fs-6">{displayList}</ul>
      </div>
    </div>
  );
};

export default PolicyCard;
