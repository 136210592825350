import React from "react";

const BlogHeader = ({ image }) => {
  return (
    <div className="container">
      <div className="my-2 blog-header">
        <div
          style={{
            backgroundImage: `url("${image}")`,
          }}
          className="blog-header-image"
        ></div>
      </div>
    </div>
  );
};

export default BlogHeader;
