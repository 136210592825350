import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dna } from "react-loader-spinner";

const ScrollableArticles = ({ allArticles }) => {
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(3);
  const loadArticles = useSelector((state) => state.article.loadArticles);

  console.log("width:" + window.innerWidth);
  console.log("height:" + window.innerHeight);

  useEffect(() => {
    if (window.innerWidth <= 530) {
      setEndPosition(1);
    }
  }, []);

  const nextArtcles = () => {
    if (
      endPosition !== allArticles.items.length &&
      endPosition <= allArticles.items.length
    ) {
      if (window.innerWidth <= 530) {
        setStartPosition(startPosition + 1);
        setEndPosition(endPosition + 1);
      } else {
        setStartPosition(startPosition + 3);
        setEndPosition(endPosition + 3);
      }
    }
  };

  const prevArtcles = () => {
    if (startPosition !== 0 && endPosition !== 3) {
      if (window.innerWidth <= 530) {
        setStartPosition(startPosition - 1);
        setEndPosition(endPosition - 1);
      } else {
        setStartPosition(startPosition - 3);
        setEndPosition(endPosition - 3);
      }
    }
  };

  const displayAriticles =
    _.slice(allArticles.items, [startPosition], [endPosition]) instanceof Array
      ? _.slice(allArticles.items, [startPosition], [endPosition]).map(
          (item, index) => {
            return (
              <Link
                key={index}
                className="d-flex justify-content-center flex-column shadow p-4 align-items-center rounded col-md-3"
                style={{ cursor: "pointer" }}
                to={{
                  pathname: `/blog/post`,
                  search: `?post_id=${
                    item.sys.id
                  }&image=${allArticles.includes.Asset.filter(
                    (imageitem) => imageitem.sys.id === item.fields.image.sys.id
                  ).map((image) => `https:${image.fields.file.url}`)}`,
                }}
              >
                {allArticles.includes.Asset.filter(
                  (imageitem) => imageitem.sys.id === item.fields.image.sys.id
                ).map((image, index) => (
                  <img
                    src={`https:${image.fields.file.url}`}
                    alt="post"
                    className="image-fluid"
                    height="100"
                    width="200"
                    key={index}
                  ></img>
                ))}

                <div
                  className="d-flex text-center pt-3"
                  style={{ fontFamily: "Carviar Dreams Bold" }}
                >
                  {item.fields.title}
                </div>
              </Link>
            );
          }
        )
      : null;

  return (
    <div className="container mb-5">
      <h6
        style={{ fontFamily: "Carviar Dreams Bold" }}
        className="my-5 fw-bold header-posts-mobile"
      >
        All Articles
      </h6>
      {loadArticles ? (
        <div className="d-flex justify-content-center my-5">
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => prevArtcles()}
            >
              <i className="fa-sharp fa-solid fa-backward fa-lg text-primary"></i>
            </div>
            {displayAriticles}
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => nextArtcles()}
            >
              <i className="fa-sharp fa-solid fa-forward fa-lg text-primary"></i>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ScrollableArticles;
