import React, { useRef } from "react";
import { Header, Footer } from "../../common";

const Frontend = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container mb-5">
        <div
          className="position-title p-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Front-End Engineer
        </div>
        <div className="shadow p-4">
          <div
            className="position-sub-title mt-3"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About PowWater
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Urban water infrastructure systems are failing the inhabitants of
            rapidly growing low and middle-income cities around the world with
            unreliable water supply and unsafe water quality. Informal private
            water vendors fill this gap but the service comes at a high social
            cost: exploitative prices, poor water quality, and use of
            unsustainable water sources. PowWater's solution is meant to disrupt
            these market dynamics to make the water purchasing experience more
            reliable, affordable, and transparent.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About the role
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            PowWater is looking for a full time front end engineer who will be
            responsible for implementing visual elements on its suite of
            products which include but are not limited to mobile and web
            applications.
          </div>
          <div
            className="about-text my-3"
            style={{ fontFamily: "Lato Regular" }}
          >
            <span className="text-black fw-bold">Location:</span> In-person
            attendance for all Kenyan candidates at our office. Remote
            attendance for all non-Kenyan candidates
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Responsibilities
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Developing mobile and web architecture</li>
              <li>
                Ensuring cross-platform optimization and responsiveness of
                PowWater’s product suite.
              </li>
              <li>
                Working alongside designers for web and mobile design elements.
              </li>
              <li>
                Overseeing overall rollout of features, products and verticals.
              </li>
              <li>Understanding product user needs.</li>
              <li>Designing user flows</li>
              <li>
                Work with the data engineers to ensure database stability,
                reliability and availability
              </li>
              <li>
                Develop specifications for new features/products and maintaining
                existing products
              </li>
              <li>
                Liaising with backend developers and to integrate the backend
                with UI elements
              </li>
              <li>
                Product performance improvement, balancing, usability,
                automation
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Qualifications
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>At least 3 years experience as a front end developer</li>
              <li>
                Proficiency in Javascript and and Javascript libraries and
                frameworks, particularly React. Experience with React Native
                preferred but not required.
              </li>
              <li>Knowledge working with REST APIs.</li>
              <li>Experience with responsive design</li>
              <li>
                Previous experience working in a startup environment preferred
                but not required
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            How to Apply
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Send the following to{" "}
            <span className="text-primary">contact@powwater.com</span>:
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Resume</li>
              <li>Link to LinkedIn profile</li>
              <li>Link to Github profile</li>
              <li>Portfolio (if available)</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Frontend;
