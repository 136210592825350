import React from "react";
import { truck } from "../../images";

const Banner = () => {
  return (
    <section id="banner">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center flex-row banner-content">
          <div className="col-lg-6 col-md-4 col-sm-6">
            <div
              className="banner-text text"
              style={{ fontFamily: "Lato Regular" }}
            >
              Kenya's <span style={{ color: "#55c2e6" }}>most reliable</span>{" "}
              clean water delivery
            </div>
          </div>
          <div className="col-lg-6 col-md-8 col-sm-6">
            <img src={truck} alt="" className="image-fluid pw-lorry" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
