import React from "react";
import forbes from "../../../images/logo-2.png";
import undp from "../../../images/logo-3.png";
import unicef from "../../../images/unicef.png";
import trf from "../../../images/nsf_logo.jpg";
import bill from "../../../images/bill_mellinda.png";
import {
  boostvc,
  goodwater_logo_color,
  Blue_Collective_Logo,
  IH2O_Logo,
  oyw,
  abh,
} from "../../../images";

const TrustedBy = () => {
  return (
    <div className="shadow-sm">
      <div className="container px-5 pb-5 trust-logos">
        <div
          className="d-flex justify-content-center fw-bold text-black pt-5 how-title"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Trusted By
        </div>
        <div className="d-flex justify-content-between flex-row flex-wrap align-items-center">
          <div className="forbes">
            <img
              src={forbes}
              alt="Forbes"
              className="image-fluid image-forbes"
            />
          </div>
          <div className="forbes">
            <img
              src={boostvc}
              alt="boostvc"
              className="image-fluid boost-image"
            />
          </div>
          <div className="forbes">
            <img
              src={bill}
              alt="BILL & MELINDA GATES Foundation"
              className="image-fluid image-bill"
            />
          </div>
          <div className="forbes">
            <img
              src={trf}
              alt="THOMSON REUTERS"
              className="image-fluid image-thomson"
            />
          </div>

          <div className="forbes">
            <img
              src={unicef}
              alt="UNICEF"
              className="image-fluid image-unicef"
            />
          </div>
          <div className="forbes">
            <img
              src={goodwater_logo_color}
              alt="goodwater_logo_color"
              className="image-fluid image-goodwater"
            />
          </div>
          <div className="forbes">
            <img
              src={Blue_Collective_Logo}
              alt="Blue_Collective_Logo"
              className="image-fluid image-blue"
            />
          </div>
          <div className="forbes">
            <img src={undp} alt="undp" className="image-fluid image-undp" />
          </div>
          <div className="forbes">
            <img
              src={IH2O_Logo}
              alt="IH2O_Logo"
              className="image-fluid image-IH2O_Logo"
            />
          </div>
          <div className="forbes">
            <img
              src={oyw}
              alt="oyw"
              className="image-fluid image-oyw"
            />
          </div>
          <div className="forbes">
            <img
              src={abh}
              alt="abh"
              className="image-fluid image-abh"
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default TrustedBy;
