import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

const NavLink = ({ active, to, title, onClick, activeState }) => {
  return (
    <li className="nav-item" onClick={onClick}>
      <Link
        className={classNames(active, "nav-link")}
        to={to}
        state={{ activeState }}
        style={{ fontFamily: "Carviar Dreams Bold" }}
      >
        {title}
      </Link>
    </li>
  );
};

export default NavLink;
