import React from "react";
import { Link } from "react-router-dom";
import { pow_full, powwater_below } from "../../images";
import moment from "moment";

const Footer = () => {
  return (
    <>
      <div className="container pt-3">
        <div className="d-flex flex-row col-md-12 detail-card">
          <div className="d-flex justify-content-between col-md-6 detail-card">
            <div className=" card-item">
              <div
                className="footer-title"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Careers
              </div>
              <Link
                style={{ fontFamily: "Lato Regular" }}
                className="text-primary footer-text"
                to="/join-us"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Open positions
              </Link>
            </div>
            <div className="card-item d-flex flex-column">
              <div
                className="footer-title"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Legal & Resources
              </div>
              <Link
                style={{ fontFamily: "Lato Regular" }}
                to="/terms-of-service"
                className="text-primary footer-text"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Terms of service
              </Link>
              <Link
                style={{ fontFamily: "Lato Regular" }}
                to="/privacy"
                className="text-primary footer-text"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Privacy policy
              </Link>
              <Link
                style={{ fontFamily: "Lato Regular" }}
                to="/water-quality"
                className="text-primary footer-text"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Understanding water quality
              </Link>
              <Link
                style={{ fontFamily: "Lato Regular" }}
                to="/terms-conditions-drivers"
                className="text-primary footer-text"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Terms & conditions (Drivers)
              </Link>
              <Link
                style={{ fontFamily: "Lato Regular" }}
                to="/terms-conditions-vendors"
                className="text-primary footer-text"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Terms & conditions (Vendors)
              </Link>
            </div>
            <div className="card-item">
              <div
                className="footer-title"
                style={{ fontFamily: "Carviar Dreams Bold" }}
              >
                Contact
              </div>
              <div
                className="text-primary footer-text"
                style={{ fontFamily: "Lato Regular" }}
              >
                contact@powwater.com
              </div>
              <div
                className="text-primary footer-text"
                style={{ fontFamily: "Lato Regular" }}
              >
                +254(701256256)
              </div>
              <div className="d-flex flex-row text-primary pt-2">
                <div className="" style={{ cursor: "pointer" }}>
                  <a
                    href="https://www.facebook.com/Powwater1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-2x fa-facebook"></i>
                  </a>
                </div>
                <div className="px-5" style={{ cursor: "pointer" }}>
                  <a
                    href="https://www.instagram.com/powwater"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-2x fa-instagram-square"></i>
                  </a>
                </div>
                <div className="" style={{ cursor: "pointer" }}>
                  <a
                    href="https://www.linkedin.com/company/powwater"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-2x fa-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column col-md-5 footer-logo">
            <div className="d-flex justify-content-end">
              <div className="d-flex flex-column">
                <img src={pow_full} alt="PowWater" height="150" />
                <img src={powwater_below} alt="PowWater" height="45" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer d-flex align-items-end">
        <div
          className=" footer-cop container"
          style={{ fontFamily: "Lato Regular" }}
        >
          Copyright {moment().format("YYYY")}, PowWater, PBC. All rights
          reserved
        </div>
      </div>
    </>
  );
};

export default Footer;
