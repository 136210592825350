import React from "react";
import locationAnimation from "../../../images/Location_animation.json";
import phoneAnimation from "../../../images/download_app_animation.json";
import selectAnimation from "../../../images/select_animation.json";
import tankerAnimation from "../../../images/tanker.json";
import { Time, AnimationCard, CustomerFeedback, TrustedBy } from ".";

const How = ({ myRef }) => {
  return (
    <>
      <div className="pb-3" ref={myRef}>
        <div className="d-flex align-items-center justify-content-center py-4">
          <span
            className="how-title"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            How PowWater Works
          </span>
        </div>
        <div className="container d-flex justify-content-between steps-container">
          <AnimationCard
            animationData={phoneAnimation}
            title="Download the app"
            content="Available on Google Play and App Store"
          />
          <AnimationCard
            animationData={locationAnimation}
            title="Choose your location"
            content="Get matched to a PowWater truck near you"
            more_classes="location-icon"
          />
          <AnimationCard
            animationData={selectAnimation}
            title="Choose your volume of water"
            content="View availability in your neighbourhood"
          />
          <AnimationCard
            animationData={tankerAnimation}
            title="Get your water delivered"
            content="Same day delivery guaranteed!"
          />
        </div>
        <Time />
        <CustomerFeedback />
        <TrustedBy />
      </div>
    </>
  );
};

export default How;
