import React from "react";

const SinglePosition = ({ title }) => {
  return (
    <div
      className="mb-3 bg-white rounded border py-5 px-4"
      style={{ cursor: "pointer" }}
    >
      <div
        className="text-primary positions-title"
        style={{ fontFamily: "Carviar Dreams Bold" }}
      >
        {title}
      </div>
    </div>
  );
};

export default SinglePosition;
