import React, { useRef } from "react";
import { Header, Footer } from "../../common";

const SalesAssociate = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container mb-5">
        <div
          className="position-title p-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Sales Associate
        </div>
        <div className="shadow p-4">
          <div
            className="position-sub-title mt-3"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About PowWater
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            PowWater is a mobile technology platform connecting high-quality
            water vendors with customers in Kenya (and soon to be East Africa &
            beyond) for a transparent, reliable, and high-quality water
            purchasing experience. Funded by Boost VC, Goodwater Capital, Blue
            Collective, and The Bill & Melinda Gates Foundation.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About the role
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            We are looking for a Sales Associate to join our efforts in Nairobi,
            Kenya. The ideal candidate should have at least 2 years of sales
            experience and a start-up mentality. You will grow more than you
            could ever imagine in this role. You will work closely with the CEO
            of the company and will play a critical role in shaping the
            company’s future.
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            This is a salary + commission B2B sales role; 80% of your time will
            be spent in the field and 20% of your time will be spent in the
            office.
          </div>
          <div
            className="about-text my-3"
            style={{ fontFamily: "Lato Regular" }}
          >
            <span className="text-black fw-bold">Location:</span> In-person
            attendance for all Kenyan candidates at our office.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Responsibilities
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Drive B2B sales through customer engagement.</li>
              <li>
                Proactively identify opportunities to grow Powwater’s brand
                awareness both online and offline to enhance Powwater’s presence
                and appeal within the city.
              </li>
              <li>
                Forecasting sales, developing “out of the box” sales
                strategies/models, and evaluating their effectiveness
              </li>
              <li>
                Evaluate and anticipate customers' needs and build productive
                long-lasting relationships
              </li>
              <li>
                Maintain and expand client databases within your assigned
                territory
              </li>
              <li>Monitor competition within the assigned region/zone</li>
              <li>
                Design and develop customer growth activities to improve
                acquisition, retention, and loyalty specifically
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Minimum Qualifications
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Have a teachable can-do attitude.</li>
              <li>An insatiable thirst for results.</li>
              <li>Startup experience preferred</li>
              <li>Understanding of the sales process and dynamics.</li>
              <li>CExcellent written and verbal communication skills.</li>
              <li>
                Superb interpersonal skills, including the ability to quickly
                build rapport with customers.
              </li>
              <li>Experience using computers for a variety of tasks.</li>
              <li>Managing digital tools and online platforms.</li>
              <li>Able to work comfortably in a fast-paced environment.</li>
              <li>
                Be a great storyteller, communicator, public speaker, and a
                master negotiator
              </li>
              <li>
                Always be discovering and prospecting with an ever-growing list
                of leads
              </li>
              <li>
                Have a commercial mindset, be able to analyze data and marketing
                analytics to make informed business decisions, and report on the
                effectiveness and efficiency of growth drivers.
              </li>
              <li>
                Be that guy/girl - you know who the users are, where it's
                happening and how to get our app in their hands
              </li>
              <li>
                Be a passionate believer in our mission to empower the world
                through sustainable safe water in Kenya and the world - living
                the PowWater values, representing the company in everything you
                do.
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Benefits
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Scale with a rapidly growing startup, with tons of opportunities for
            growth Contribute to our mission and work in our dynamic and
            fast-paced team Competitive base salary based on experience and the
            opportunity to earn as much as you want to based on commissions.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            How to Apply
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Interested Candidates should send their CV to{" "}
            <span className="text-primary">recruitment@powwater.com</span>
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <span>
              Applications are considered on a rolling basis. Please note, only
              candidates selected for an interview will be contacted
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SalesAssociate;
