import React, { useState, useEffect } from "react";

const Time = () => {

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 4) {
        setSeconds(0);
      } else {
        setSeconds((seconds) => seconds + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className="bg-primary d-flex justify-content-center">
      <div className="d-flex justify-content-center flex-column align-items-center py-5">
        <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="time-text" style={{ fontFamily: "Carviar Dreams Bold" }}>
            Time is{" "}
            <span
              className="power-text"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              {seconds === 0
                ? "Power"
                : seconds === 1
                ? "Happiness"
                : seconds === 2
                ? "Money"
                : seconds === 3
                ? "Family"
                : "Freedom"}
            </span>
          </div>
          <div
            className="power-text"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          ></div>
        </div>
        <div
          className="fs-4 text-white text-spend px-3"
          style={{ fontFamily: "Lato Regular" }}
        >
          Spend time doing what you love, not waiting for water
        </div>
      </div>
    </div>
  );
};

export default Time;
