import React from "react";

const AppLink = ({ image, className, alt }) => {
  return (
    <div className="">
      <img src={image} alt={alt} className={className} />
    </div>
  );
};

export default AppLink;
