import React from "react";

const PolicyTable = () => {
  return (
    <table className="table table-bordered">
      <thead className="bg-secondary">
        <tr>
          <th scope="col">Category</th>
          <th scope="col">Partner</th>
          <th scope="col">Further Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Analytics</td>
          <td>Google</td>
          <td>
            <a
              href="https://policies.google.com/technologies/partner-sites?hl=en-US"
              target="_blank"
              className="text-primary text-decoration-underline"
              rel="noopener noreferrer"
            >
              How Google uses information from sites or apps that use our
              services
            </a>
          </td>
        </tr>
        <tr>
          <td>Social Media</td>
          <td>Facebook</td>
          <td>
            <a
              href="https://free.facebook.com/privacy/policy"
              target="_blank"
              className="text-primary text-decoration-underline"
              rel="noopener noreferrer"
            >
              Data Policy
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default PolicyTable;
