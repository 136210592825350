import React from "react";
import { logo, appStore, googlePlay } from "../../images";

const DownloadPage = () => {
  return (
    <div className="download-page d-flex justify-content-center align-items-center">
      <div className="shadow d-flex justify-content-center align-items-center p-4 bg-white rounded flex-column">
        <img src={logo} alt="PowWater" height="120" />
        <h3 className="py-3 text-center title-download col-md-7 mt-3">
          Quality-tested water at the tap of a button!
        </h3>

        <div className="">
          <a href="https://apps.apple.com/ke/app/powwater/id1565603273">
            <img
              src={appStore}
              alt="App Store"
              className="image-fluid appstore-link"
            />
          </a>
        </div>
        <div className="">
          <a href="https://play.google.com/store/apps/details?id=com.powwater.customer">
            <img
              src={googlePlay}
              alt="App Store"
              className="image-fluid playstore-link"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
