import React, { useRef } from "react";
import { Header, Footer } from "../../common";
import { CareerBanner, Positions, Values } from ".";

const Career = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  // comment
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
        careerActive="active"
      />
      <CareerBanner />
      <Positions />
      <Values />
      <Footer />
    </>
  );
};

export default Career;
