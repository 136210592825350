import React, { useRef } from "react";
import { Header } from "../../components/common";

const DeleteAccount = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);

  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
        blogPropActive="active"
      />

      <div>
        <iframe
          title="Google Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSePW6u9SoWvAaXyuw015Ogwj-AB1HG3sdpK2z65Yq89gmR2xA/viewform?embedded=true" // Replace 'your_form_id' with your actual form ID
          width="100%"
          height="800"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading...
        </iframe>
      </div>
    </>
  );
};

export default DeleteAccount;
