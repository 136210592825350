import React from "react";
import "./styles/hk-groteks-cufonfonts-webfont/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./styles/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  DownloadPage,
  Career,
  FullStack,
  Frontend,
  TermsOfService,
  Policy,
  TermsAndConditionDriver,
  TermsAndConditionVendor,
  Verify,
  OperationsAssistant,
  SalesAssociate,
  AllArticles,
  SingleArticle,
  AccountDeletion,
  WaterQuality,
  TempPage,
  DeleteAccount
} from "./components/pages";
import "@fortawesome/fontawesome-free/css/all.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-download" element={<DownloadPage />} />
        <Route path="/why" element={<Home />} />
        <Route path="/how" element={<Home />} />
        <Route path="/join-us" element={<Career />} />
        <Route path="/blog" element={<AllArticles />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/blog/post" element={<SingleArticle />} />
        <Route path="/full-stack-engineer" element={<FullStack />} />
        <Route path="/front-end-engineer" element={<Frontend />} />
        <Route path="/account-deletion" element={<AccountDeletion />} />
        <Route
          path="/field-operations-assistant"
          element={<OperationsAssistant />}
        />
        <Route path="/sales-associate" element={<SalesAssociate />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy" element={<Policy />} />
        <Route path="/water-quality" element={<WaterQuality />} />
        <Route path="/verify/:verificationCode" element={<Verify />} />
        <Route
          path="/terms-conditions-drivers"
          element={<TermsAndConditionDriver />}
        />
        <Route
          path="/terms-conditions-vendors"
          element={<TermsAndConditionVendor />}
        />
        <Route path="/temp" element={<TempPage />} />
      </Routes>
    </Router>
  );
};

export default App;
