import axios from "axios";

// base url


// axios.defaults.baseURL = `https://blog-application-6bpq7f4ctq-de.a.run.app`;
// axios.defaults.baseURL = `http://localhost:5000`;
axios.defaults.baseURL = `https://cdn.contentful.com/spaces/9odxlpu1qrxp`;

axios.interceptors.request.use((request) => {
  console.log("Starting Request", request);
  return request;
});

axios.interceptors.response.use((response) => {
  console.log("Response:", response);
  return response;
});

export default axios;
