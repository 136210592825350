import React, { useRef } from "react";
import { Header, Footer } from "../../common";

const OperationsAssistant = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container mb-5">
        <div
          className="position-title p-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Field Operations Assistant
        </div>
        <div className="shadow p-4">
          <div
            className="position-sub-title mt-3"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About PowWater
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            PowWater is a mobile technology platform connecting high-quality
            water vendors with customers in Kenya (and soon to be East Africa &
            beyond) for a transparent, reliable, and high-quality water
            purchasing experience. Funded by Boost VC, Goodwater Capital, Blue
            Collective, and The Bill & Melinda Gates Foundation.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            About the role
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            If you are passionate about empowering the world through sustainable
            safe drinking water, then read on! We are looking for a Field
            Operations Assistant to join our team and support PowWater by
            managing the day-to-day requirements in the field. The Field
            Operations Assistant will be responsible for ensuring that
            high-quality service is delivered. We are looking for someone who
            values compassion, transparency, challenges, and trust and is a high
            achiever because this position will entail full autonomy and
            reporting directly to the Operations Lead.
          </div>
          <div
            className="about-text my-3"
            style={{ fontFamily: "Lato Regular" }}
          >
            <span className="text-black fw-bold">Location:</span> In-person
            attendance for all Kenyan candidates at our office.
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Responsibilities
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>
                Oversee orders to ensure that they are delivered on time and in
                a professional manner
              </li>
              <li>
                Proactively check status of fill sites and the status of water
                available
              </li>
              <li>Train drivers and conductors</li>
              <li>
                Manage drivers to ensure that they are following PowWater
                protocol
              </li>
              <li>
                Coordinate with the driver teams to ensure deliveries are done
                in the shortest time possible whilst maintaining a great overall
                customer experience
              </li>
              <li>
                Establish and maintain good rapport with customers by using
                positive language and anticipating their needs
              </li>
              <li>
                Use customer insight to propose new ideas on how to improve
                PowWater and recommend new products/services or make suggestions
                for improvements by identifying relevant features and benefits
              </li>
              <li>
                Maintain a positive, empathetic, and professional attitude
                toward customers at all times giving customers a remarkable
                experience worth talking about
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Minimum Qualifications
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>1-3 years experience</li>
              <li>
                A self-starter, able to prioritize and manage multiple
                assignments, take the initiative, and work independently to a
                deadline.
              </li>
              <li>
                A hard worker who is flexible and eager. The promise of growth
                excites you.
              </li>
              <li>
                A high degree of proficiency working in a fast-paced, flexible,
                goal-oriented environment, with the highest level of personal
                and professional integrity
              </li>
              <li>
                Considerable knowledge of tools, methods, operations, and
                materials used in the utility maintenance department.
              </li>
              <li>
                Excellent organization and time management skills; experience
                working in high paced environment
              </li>
              <li>
                Considerable ability to climb ladders, and use tools and objects
                requiring manual dexterity
              </li>
              <li>Willingness to work long hours </li>
              <li>
                Must be able to work in areas with exposure to various
                environmental conditions such as dust, dirt, noise, disagreeable
                odors, and humidity
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            Required skills and competencies
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <ul>
              <li>Determination and self-drive to achieve success</li>
              <li>
                The ability to work with a team, follow instructions, and great
                understanding of fill sites and the water industry
              </li>
              <li>
                Bachelor’s degree in a related area is desired but not required
              </li>
              <li>
                A well-versed communicator at all organizational levels, with
                strong oral and written skills; required fluency in English and
                Kiswahili
              </li>
              <li>
                Strong organization and time management skills, rigorous,
                analytic and details oriented, creative and innovative thinking
              </li>
            </ul>
          </div>
          <div
            className="position-sub-title mt-2"
            style={{ fontFamily: "Carviar Dreams Bold" }}
          >
            How to Apply
          </div>
          <div className="about-text" style={{ fontFamily: "Lato Regular" }}>
            Please apply by sending an email to{" "}
            <span className="text-primary">recruitment@powwater.com</span>
          </div>
          <div className="" style={{ fontFamily: "Lato Regular" }}>
            <span>
              Applications are considered on a rolling basis. Please note, only
              candidates selected for an interview will be contacted
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OperationsAssistant;
