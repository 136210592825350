import React, { useEffect, useState } from "react";
import { appStore, googlePlay } from "../../../images";
import { AppLink } from "../../common";
import { PowerOfWater } from ".";
import axios from "axios";
import { InfinitySpin } from "react-loader-spinner";

const AboutUs = ({ myRef }) => {
  const [volume, setVolume] = useState("");
  const [loading, setLoading] = useState(false);
  let tokenStr =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhMmVhZGI5Ny1lYTIwLTRlMGYtYjJhNy05NDEzY2M4NWViYzEiLCJyb2xlIjoiQWRtaW4iLCJpYXQiOjE2NzI3MjI2MzR9.RnybHROTOGpZQL8YDYr0tuJvXLoUSPJ3zDt0972QyBQ";
  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://production-backend-6bpq7f4ctq-de.a.run.app/stats/summary`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      })
      .then((response) => {
        setVolume(response.data.total + 607207);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div ref={myRef}>
        <div className="container d-flex justify-content-between px-3 flex-wrap app-download-section">
          <div className="d-flex  align-items-center flex-row all-imapacted-text">
            <span
              className="impacted-text-value"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              {loading ? (
                <InfinitySpin width="100" color="#0a62ae" />
              ) : (
                String(volume).slice(0, 3)
              )}{" "}
              Million +
            </span>
            <span
              className="impacted-text ms-3"
              style={{ fontFamily: "Carviar Dreams Bold" }}
            >
              Litres of clean water delivered
            </span>
          </div>
          <div className="d-flex flex-row align-items-center flex-wrap app-links">
            <a href="https://play.google.com/store/apps/details?id=com.powwater.customer">
              <AppLink
                image={googlePlay}
                alt="Play Store"
                className="image-fluid playstore-link"
              />
            </a>

            <a href="https://apps.apple.com/ke/app/powwater/id1565603273">
              <AppLink
                image={appStore}
                alt="App Store"
                className="image-fluid appstore-link"
              />
            </a>
          </div>
        </div>
        <PowerOfWater />
      </div>
    </>
  );
};

export default AboutUs;
