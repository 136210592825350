import React, { useEffect, useState } from "react";
import verify_email from "../../images/verify_email.json";
import failed_verification from "../../images/failed_verification.json";
import Lottie from "lottie-react";
import { useParams } from "react-router";
import axios from "axios";

const Verify = () => {
  const { verificationCode } = useParams();
  const [showSuccess, setSuccess] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    axios
      .post(
        `https://production-backend-6bpq7f4ctq-de.a.run.app/auth/email/verify`,
        {
          verificationCode,
        }
      )
      .then((response) => {
        console.log(response.data.message);
        setSuccess(true);
        setMessage(response.data.message);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setSuccess(false);
        setMessage(err.response.data.message);
      });
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="p-5">
        <div
          className="text-primary forbes"
          style={{ fontFamily: "Carviar Dreams Bold", fontSize: "17px" }}
        >
          {showSuccess && "Now that’s a welcome blue tick! 😉"}
        </div>
        {showSuccess ? (
          <div className="forbes my-3">
            <Lottie
              animationData={verify_email}
              loop={true}
              className=""
              style={{ width: "200px" }}
            />
          </div>
        ) : (
          <div className="forbes my-3">
            <Lottie
              animationData={failed_verification}
              loop={true}
              className=""
              style={{ width: "200px" }}
            />
          </div>
        )}

        <div
          className="forbes my-3 text-primary text-capitalize"
          style={{ fontFamily: "Carviar Dreams Bold", fontSize: "17px" }}
        >
          {message}!
        </div>
        <div className="forbes pt-4">
          <a
            href="myapp://"
            className="btn btn-primary text-white fw-bolder p-2 fs-5"
          >
            {showSuccess ? "Close and get me some water!" : "Try again later"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Verify;
