import React, { useEffect, useState, Fragment } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FallingLines } from "react-loader-spinner";

const ArticlesSlide = ({ articles }) => {
  const loadArticles = useSelector((state) => state.article.loadArticles);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const displayArticles =
    articles.items instanceof Array
      ? articles.items.slice(0, 3).map((item, index) => {
          return (
            <Carousel.Item className="" key={index}>
              <div
                className="col-md-12 d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="article-image-container">
                  {articles.includes.Asset.filter(
                    (imageitem) => imageitem.sys.id === item.fields.image.sys.id
                  ).map((image, index) => (
                    <img
                      src={`https:${image.fields.file.url}`}
                      className="d-block slide-image"
                      alt="image"
                      key={index}
                    ></img>
                  ))}
                </div>
              </div>

              <Carousel.Caption>
                {/* <h3
                  style={{ fontFamily: "Carviar Dreams Bold" }}
                  className="blog-slider-header"
                >
                  {item.fields.title}
                </h3> */}
                <Link
                  className="btn bg-white shadow text-black btn-blog-mobile"
                  to={{
                    pathname: `/blog/post`,
                    search: `?post_id=${
                      item.sys.id
                    }&image=${articles.includes.Asset.filter(
                      (imageitem) =>
                        imageitem.sys.id === item.fields.image.sys.id
                    ).map((image) => `https:${image.fields.file.url}`)}`,
                  }}
                  style={{ fontFamily: "Lato Regular" }}
                >
                  Read this article
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })
      : null;

  return (
    <div className="container">
      {loadArticles ? (
        <div className="d-flex justify-content-center my-5">
          <FallingLines
            visible={true}
            height="80"
            width="80"
            ariaLabel="falling-lines-loading"
            wrapperStyle={{ color: "blue" }}
            wrapperClass="dna-wrapper text-black"
            color="#0a62ae"
          />
        </div>
      ) : (
        <div className="bg-primary">
          <Carousel activeIndex={index} onSelect={handleSelect}>
            {displayArticles}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default ArticlesSlide;
