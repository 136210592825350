import React from "react";
import { SinglePosition } from ".";
import { Link } from "react-router-dom";

const Positions = () => {
  return (
    <div className="bg-light pb-3">
      <div className="container">
        <div
          className="d-flex justify-content-center career-title align-items-center py-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Open Positions
        </div>
        {/* <Link to="/full-stack-engineer">
          <SinglePosition title="Full-Stack Engineer" />
        </Link> */}
        {/* <Link to="/front-end-engineer">
          <SinglePosition title="Front-End Engineer" />
        </Link> */}
        {/* <Link to="/field-operations-assistant">
          <SinglePosition title="Field Operations Assistant" />
        </Link> */}
        <Link to="/sales-associate">
          <SinglePosition title="Sales Associate" />
        </Link>
      </div>
    </div>
  );
};

export default Positions;
