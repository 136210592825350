import React, { useRef } from "react";
import { Header, Footer, TermsCard } from "../common";

const TermsAndConditionDriver = () => {
  const myRefWhy = useRef(null);
  const myRefHow = useRef(null);
  const myRefHome = useRef(null);
  return (
    <>
      <Header
        scrollToWhy={() => myRefWhy.current.scrollIntoView()}
        scrollToHow={() => myRefHow.current.scrollIntoView()}
        scrollToHome={() => myRefHome.current.scrollIntoView()}
        myRef={myRefHome}
      />
      <div className="container  mb-5">
        <div
          className="text-black fw-bold fs-4 my-3"
          style={{ fontFamily: "Carviar Dreams Bold" }}
        >
          Additional Terms and Conditions for PowWater Drivers
        </div>
        <div className="shadow p-4">
          <TermsCard content="The following terms and conditions (“Additional Terms”) apply to those persons using the Services to deliver water to PowWater users (respectively, “Drivers” and “Users”) and apply in addition to the PowWater Terms of Service. In the event of any conflict between these Additional Terms and the Terms of Service, the Additional Terms will apply." />
          <TermsCard
            number="1. "
            title="The Role of Drivers "
            content="  PowWater’s Services connect Drivers with Users and Vendors to facilitate the delivery of water. Through the Services, Drivers can locate and accept water deliveries. Drivers are responsible for completing all assigned deliveries within the suggested time frame."
          />
          <TermsCard
            number="2. "
            title="Representations and Warranties: "
            content=" In addition to the representations and warranties in the Terms of Service, you represent and warrant that you possess a valid driver’s license, certificate of good standing, and other approvals necessary to perform the delivery services and that you will maintain a current and valid driver’s license and such other approvals throughout the time you drive for Users. You further represent and warrant that you have appropriate transportation to complete deliveries."
          />
          <TermsCard
            number="3. "
            title="Personal Conduct: "
            content=" You acknowledge and understand that you will be transporting potentially critical water supplies to Users. You agree that you will not transport any other materials while driving for Users, including, but not limited to, plants, animals, chemicals, or other potentially hazardous or contaminating substances."
          />
          <TermsCard content="In making deliveries, you will have access to potentially sensitive information, such as an individual's name, phone number, payment method, and address. You agree that you will not share or use sensitive information for any purpose other than making a delivery. You agree that you will not contact Users for any purpose other than facilitating delivery." />
          <TermsCard content="You agree that you will promptly inform PowWater, Vendors, and Users (as applicable) of any delays in your delivery, any issues you encounter in transit, and if you are unable to complete an accepted delivery." />
          <TermsCard content="You acknowledge and agree that any deliveries you accept through the Services will be completed by you; you will not ask any other individual to complete a delivery on your behalf or to drive your vehicle while completing a delivery. You also will not separately contract with Users or Vendors outside of the Services to receive or complete orders or charge Users a higher price than the one you agreed to via the Service. To the extent that it is reasonably needed and sensible, you may delegate a task to another driver registered with PowWater." />
          <TermsCard content="You agree that you will abide by all PowWater policies, including the PowWater Driver Handbook, at all times while providing transportation services in association with PowWater." />
          <TermsCard
            number="4. "
            title="PowWater is Not Responsible: "
            content=" PowWater only enables connections between Users, Drivers, and Vendors. PowWater is not responsible for the performance or actions of Users, Drivers, or Vendors, nor does it have control over the quality, timing, legality, failure to provide, or any other aspect whatsoever of Users, Drivers, or Vendors. PowWater makes no representations about the reliability or accuracy of information provided by Users, including regarding delivery location, road conditions, or other aspects of delivery. PowWater is not responsible for the water provided by Vendors, including the containers provided by Vendors for transport. PowWater explicitly disclaims all responsibility and liability for damage to your vehicle, person, or other property incurred while you are a Driver."
          />
          <TermsCard
            number="5. "
            title="Independent Contractors: "
            content=" Drivers are independent contractors and not employees of PowWater. PowWater does not certify Drivers’ qualifications. PowWater does not supervise, direct, control or monitor Drivers’ work and is not responsible for the work performed or the delivery process in any manner."
          />
          <TermsCard
            number="6. "
            title="Payment: "
            content=" You will receive payment based on hours worked in accordance with these Additional Terms, the Terms of Service, and any delivery commitments made via the Services. PowWater will remit payments weekly via your M-Pesa on file. You are solely responsible for paying any applicable taxes on money earned as a Driver."
          />
          <TermsCard
            number="7. "
            title="Suspension and Termination: "
            content=" PowWater reserves the right to suspend or terminate a Driver at any time, for any reason. Upon termination, PowWater will remit to you any earned payments via your payment method on file."
          />
          <TermsCard
            number="8. "
            title="No Agency: "
            content=" Nothing herein contained shall be deemed to create an agency, joint venture, amalgamation, partnership, employment or similar relationship between PowWater and the Drivers. Notwithstanding any of the provisions of these Terms and Conditions, neither Party shall at any time enter into, incur, or hold itself out to third Parties as having authority to enter into or incur, on behalf of the other Party, any commitment, expense, or liability whatsoever, and all contracts, expenses and liabilities undertaken or incurred by one Party in connection with or relating to the delivery of water to PowWater Users shall be undertaken, incurred or paid exclusively by that Party, and not as an agent or representative of the other Party."
          />
          <TermsCard
            number="9. "
            content="These Terms and Conditions may change from time to time. Any such changes shall be communicated to you before coming into effect."
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionDriver;
